import { useCallback, useState, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';

import { DocumentFile } from '../DocumentFile/document-file.component';
import { UploadDocument } from '../../Dialogs/UploadDocument/upload-document.component';
import { usePopup } from '@lib/hooks/usePopup';
import documentsService from '@lib/services/documents-service';
import { useManageDealContext } from '@lib/Context/ManageDealContext/manage-deal.context';
import { stepStatusRejected } from '@base/globalVariables/global-variables';
import Portal from '@molecules/Portal/portal.component';
import PopupLayout from '@organisms/Popup/PopupLayout/popup-layout.component';

import styles from './single-document.style.module.scss';
import { useUserRole } from '@lib/hooks/useUserRole';

const Component = ({
  stepDocument,
  archivedDeal,
  onRefreshDocuments,
  error,
  step,
  view,
  canWrite,
  updateDocuments,
  activePage,
  getAllDealDocumentsHandler,
  ...props
}) => {
  const { t } = useTranslation();
  const { showPopup } = usePopup();
  const { deal, setDocuments } = useManageDealContext();

  const [attachFileDialog, setAttachFileDialog] = useState(false);
  const [fileInitialVersion, setFileInitialVersion] = useState('');
  const { isFinancier, isInsurer, isWarehouseAgent } = useUserRole();

  //fixes for https://almacenaplatform.atlassian.net/browse/ATE-2024
  useEffect(() => {
    if (step?.Status === stepStatusRejected) {
      if (
        step?.StepMainDocument === stepDocument?.Name &&
        stepDocument?.Files[0]
      ) {
        setFileInitialVersion(stepDocument?.Files[0]?.VersionNumber);
      }
    }
  }, []);

  const deleteDocumentHandler = useCallback(
    (documentId, versionId) => {
      // documentsService
      //   .deleteDocument(documentId, deal.Id)
      //   .then(({ data }) => {
      //     const tempDocuments = (data.Documents).reduce(
      //       (acc, curr) => {
      //         const res = curr.Documents ? curr.Documents[0] : curr;
      //
      //         return [...acc, res];
      //       }, []);
      //
      //     setDocuments(tempDocuments);
      //   })
      //   .catch(console.error);
      documentsService
        .deleteDocumentVersion(deal.Id, documentId, versionId)
        .then((r) => {
          const tempDocuments = r.data.Documents.reduce((acc, curr) => {
            const res = curr.Documents ? curr.Documents[0] : curr;

            return [...acc, res];
          }, []);
          if (stepDocument.ServiceType) {
            updateDocuments(tempDocuments);
          } else {
            getAllDealDocumentsHandler(deal.Id);
            setDocuments(tempDocuments);
          }
        })
        .catch(console.error);
    },
    [deal.Id]
  );

  const submitForSignatureDocumentHandler = useCallback(
    (payload) => {
      return documentsService.signDocument(payload).then(({ data }) => {
        showPopup({
          state: 'success',
          message: t('SignatureDocumentSubmitted'),
          timer: 4000,
        });
        data?.Documents?.length && setDocuments(data?.Documents);
        return data;
      });
    },
    [showPopup, t]
  );

  const renderSection = () => {
    if (view && view === 'all') {
      return (
        <>
          {((canWrite && !isFinancier && !isInsurer && !isWarehouseAgent) ||
            ((isFinancier || isInsurer || isWarehouseAgent) &&
              activePage === 'ServiceOrders' &&
              canWrite)) && (
            <div className={styles.uploadFileWrap}>
              <UploadDocument
                isEdit={false}
                step={step}
                dealStepId={stepDocument?.DealStepId}
                documentTypeId={stepDocument?.Id}
                onRefreshDocuments={onRefreshDocuments}
                canWrite={canWrite}
                serviceType={stepDocument.ServiceType}
                updateDocuments={updateDocuments}
                getAllDealDocumentsHandler={getAllDealDocumentsHandler}
              />
              {!!error && (
                <FormHelperText error={!!error}>{error.message}</FormHelperText>
              )}
            </div>
          )}
          <>
            {stepDocument.Files.map((file) => (
              <DocumentFile
                key={file.DocumentId}
                {...props}
                stepDocument={stepDocument}
                noEditAction={false}
                file={file}
                showStatus={false}
                archivedDeal={archivedDeal}
                onDeleteDocument={deleteDocumentHandler.bind(
                  this,
                  file.DocumentId,
                  file.LatestDocumentVersionId
                )}
                onSubmitForSignatureDocument={submitForSignatureDocumentHandler}
                onRefreshDocuments={onRefreshDocuments}
                canWrite={canWrite}
                activePage={activePage}
              />
            ))}
          </>
        </>
      );
    } else {
      return (
        <>
          {stepDocument?.Files &&
          stepDocument?.Files.length > 0 &&
          fileInitialVersion !== stepDocument.Files[0].VersionNumber ? (
            stepDocument.Files.map((file) => (
              <DocumentFile
                key={file.VersionNumber}
                {...props}
                step={step}
                stepDocument={stepDocument}
                noEditAction={false}
                file={file}
                showStatus={false}
                archivedDeal={archivedDeal}
                onDeleteDocument={deleteDocumentHandler.bind(
                  this,
                  file.DocumentId,
                  file.LatestDocumentVersionId
                )}
                onSubmitForSignatureDocument={submitForSignatureDocumentHandler}
                onRefreshDocuments={
                  activePage === 'documents'
                    ? () => getAllDealDocumentsHandler(deal.Id)
                    : onRefreshDocuments
                }
                canWrite={canWrite}
                activePage={activePage}
              />
            ))
          ) : (
            <div className={styles.uploadFileWrap}>
              {((canWrite && !isFinancier && !isInsurer && !isWarehouseAgent) ||
                ((isFinancier || isInsurer || isWarehouseAgent) &&
                  activePage === 'ServiceOrders' &&
                  canWrite)) && (
                <>
                  <UploadDocument
                    isEdit={false}
                    step={step}
                    dealStepId={stepDocument?.DealStepId}
                    documentTypeId={stepDocument?.Id}
                    onRefreshDocuments={onRefreshDocuments}
                    canWrite={canWrite}
                    serviceType={stepDocument.ServiceType}
                    updateDocuments={updateDocuments}
                    getAllDealDocumentsHandler={getAllDealDocumentsHandler}
                    activePage={activePage}
                    document={stepDocument}
                  />
                  {!!error && (
                    <FormHelperText error={!!error}>
                      {error.message}
                    </FormHelperText>
                  )}
                </>
              )}
            </div>
          )}
        </>
      );
    }
  };

  // If the status of the deal is Rejected show the upload document section
  return (
    <div className={styles.group}>
      {attachFileDialog && (
        <Portal overlay={'overlay'}>
          <PopupLayout closePopUp={setAttachFileDialog}>
            <UploadDocument
              title={t('AttachDocuments')}
              isEdit={false}
              onClose={() => setAttachFileDialog(false)}
              step={step}
              dealStepId={stepDocument.DealStepId}
              documentTypeId={stepDocument.Id}
              onRefreshDocuments={onRefreshDocuments}
              canWrite={canWrite}
              getAllDealDocumentsHandler={getAllDealDocumentsHandler}
            />
          </PopupLayout>
        </Portal>
      )}

      {renderSection()}
    </div>
  );
};

export const SingleDocument = memo(Component);
